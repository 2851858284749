import React from "react";
import { WithAuth } from "../../components/molecules/Auth/WithAuth";
import { GiftJobPage } from "../../components/pages/AdminPage/GiftJob/GiftJobPage";

const Page: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <WithAuth adminOnly={true}>
            <GiftJobPage />
        </WithAuth>
    );
};

export default Page;
